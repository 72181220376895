import { ApolloClient } from '@apollo/client'
import type { ApolloContext } from '@/services/types/Gql'
import { ContactTagType } from '@/types/generated'
import type { IContactType, ISingleContactType, IRemoteCandidateConnection, IRemoteCandidateConnectionResult } from '@/services/types/Contact'
import PaginationParams from '@/services/injectables/gql/UtilsGql'

import findContacts from '@/graphql/contacts/findContacts.gql'
import findContactTags from '@/graphql/contacts/findContactTags.gql'
import findIntegrationsCandidates from '@/graphql/contacts/findIntegrationsCandidates.gql'

interface IContactGql {
  $apollo: ApolloClient<any>
  find: (tenantId: string, phoneNumberId: string, idFilter: Array<string | null>, paginationParams: PaginationParams) => Promise<{ results: Array<ISingleContactType> }>
  findTagsByText: (tenantId: string, phoneNumberId: string, text: string) => Promise<ContactTagType>
  findIntegrationContacts: (tenantId: string, phoneNumber: string, paginationParams: PaginationParams) => Promise<IRemoteCandidateConnectionResult>
}

export default class ContactGql implements IContactGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  async find (tenantId: string, phoneNumberId: string, idFilter: Array<string | null>, paginationParams: PaginationParams) {
    const { data: { contacts } }: { data: { contacts: IContactType } } = await this.$apollo.query({
      query: findContacts,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberId,
        idFilter,
        ...paginationParams
      }
    })
    const fixedContacts = contacts.results?.map((contact: ISingleContactType | null) => ({
      ...contact,
      tags: contact?.tags?.map(tag => tag?.value)
    }))
    return {
      ...contacts,
      results: fixedContacts
    } as { results: Array<ISingleContactType> }
  }

  async findTagsByText (tenantId: string, phoneNumberId: string, text: string) {
    const { data: { contactTags } }: { data: { contactTags: ContactTagType } } = await this.$apollo.query({
      query: findContactTags,
      variables: {
        tenantId,
        phoneNumberId,
        text2Search: text
      }
    })
    return contactTags
  }

  async findIntegrationContacts (tenantId: string, phoneNumber: string, paginationParams: PaginationParams) {
    const { data: { remoteCandidates } }: { data: { remoteCandidates: IRemoteCandidateConnection } } = await this.$apollo.query({
      query: findIntegrationsCandidates,
      variables: {
        tenantId,
        phoneNumber,
        ...paginationParams
      }
    })
    return { results: remoteCandidates }
  }
}
